<template>
  <div>
    <vs-row>
      <!-- title -->
      <vs-col class="cd-form-group" vs-type="flex" vs-align="center">
        <vs-button type="flat" icon="reply" id="funduscopyBack" @click="backFunction"></vs-button>
        <h5 class="mx-2">{{$t('FunduscopyAIReportTxt')}}</h5>
        <vs-chip :color="opinionStatus==1 ? 'grey':'danger'" v-if="opinionStatus==0||opinionStatus==1">
          {{ opinionStatus==1 ? $t('Finish'):$t('Processing2') }}
        </vs-chip>
      </vs-col>
      <!-- 會診紀錄 -->
      <vs-col class="cd-form-group" v-if="opinionAllData!=null">
        <p>From :{{opinionAllData.dname}}</p>
        <p v-show="opinionState">{{opinionAllData.message}}</p>
        <p>To:{{myProfile.name}}</p>
        <div v-if="opinionStatus==0">
          <el-input
            type="textarea"
            :rows="2"
            :placeholder="$t('EnterSuggestedContent')"
            v-model="opinion">
          </el-input>
          <vs-button class="cd-button-2w" @click="saveOpinion" :disabled="opinionStatus==1">{{$t('Save')}}</vs-button>
        </div>
        <p v-else>{{opinion}}</p>
      </vs-col>
      <!-- 眼底鏡圖片 -->
      <vs-col class="cd-form-group">
        <p type="danger">{{$t('AIReportTxt')}}</p>
        <div v-viewer class="images clearfix" style="display: flex;">
          <img :id="'funduscopy_image_'+index" style="cursor: pointer" class="ml-2" width="170" height="160" :src="'https://upload.curdoctor.com.tw/funduscopy/'+image.uploadimg" v-for="(image, index) in imageurl" :key="index" @click.prevent="syncMe(index)">
        </div>
      </vs-col>
      <!-- 左圖 -->
      <vs-col vs-w="6">
        <div class="cd-card-group">
          <vs-card fixedHeight>
            <ve-gauge :data="chartData" :settings="chartSettings" height="300px" width="450px"></ve-gauge>
          </vs-card>
        </div>
      </vs-col>
      <!-- 右圖 -->
      <vs-col vs-w="6">
        <div class="cd-card-group">
          <vs-card fixedHeight>
            <ve-bar :data="aiData" :settings="aichartSettings" height="300px" width="450px"></ve-bar>
          </vs-card>
        </div>
      </vs-col>
      <!-- 左圖備註 -->
      <vs-col vs-w="6">
        <p class="cd-p-small" v-for="(text, index) in funduscopyAI2Txt" :key="index">{{text}}</p>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  watch: {
    '$route'(to, from) {
      if (this.verticalNavMenuItemsMin) return
      let scrollHeight = this.scrollHeight
      // Collapse Group
      if (this.openItems && !this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = `${0}px`
        }, 50)
      // Expand Group
      } else if (this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = 'none'
        }, 300)
      }
    },
    maxHeight() {
      this.openItems = this.maxHeight != '0px'
    },
    // syncTriggle(v) {
    //   if(v=='emrtab'){
    //     document.getElementById(v).click();
    //   }else if(v=='funduscopyBack'){
    //     setTimeout(() => {
    //       var tt = document.querySelector('#funduscopyBack')
    //       tt.click()
    //     }, 200);
    //   }else if(v=='closefunduscopy'){
    //     setTimeout(() => {
    //       var tt = document.querySelector('.viewer-button')
    //       tt.click()
    //     }, 200);
    //   }else{
    //     document.getElementById(v).click();
    //   }
    //   this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: v})
    // }
  },
  async created(){
    await this.$store.dispatch('fetchProfile')
  },
  data() {
    this.chartSettings = {
      dataType: {
        'speed': 'percent'
      },
      dataName: {
        'speed': this.$t('TransferTxt')
      },
      seriesMap: {
        'speed': {
          min: 0,
          max: 1,
          startAngle: 180,
          endAngle: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: [[0.2, '#006400'],[0.5, '#FFD700'],[0.8, '#FF8C00'],[1, '#DC143C']],
              width: 30
            }
          }
        }
      }
    }
    this.aichartSettings = {
      dimension: ['type'],
      metrics: ['value']
    }
    return {
      opinion: '',
      imageurl: '',
      loading: false,
      selectid: -1,
      caseid: -1,
      eyesBarSession: {
        series: [{
          data: ['100', '1', '1', '1', '1']
        }],
      },
      eyesChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        states: {
          hover: {
            filter: 'none'
          }
        },
        colors: ['#7367f0', '#7367f0', '#7367f0', '#7367f0', '#7367f0', '#7367f0', '#7367f0'],
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Proliferative', 'Severe', 'Moderate', 'Mild', 'No'],
        },
      },
      chartData: {
        columns: ['type', 'value'],
        rows: [{
          type: 'speed',
          value: 0
        }]
      },
      aiData: {
        columns: ['type', 'value'],
        rows: [
          { 'type': 'No', 'value': 0, 'itemStyle': {'color': 'blue'}, 'content': 'ABC' },
          { 'type': 'Mild', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': 'QWE' },
          { 'type': 'Moderate', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '1234' },
          { 'type': 'Severe', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '' },
          { 'type': 'Proliferative', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '' }
        ]
      },
      tokenId: localStorage.getItem('di_tokenId'),
      funduscopyAI2Txt: this.$t('FunduscopyAI2Txt').split(' || '),
    }
  },
  computed: {
    // syncTriggle: {
    //   get() {
    //     return this.$store.getters.syncTriggle
    //   },
    //   set(val) {
    //     this.$store.commit('SET_SYNC_TRIGGLE', val)
    //   }
    // },
    // syncStatus(){
    //   console.log('syncStatus、')
    //   return this.$store.getters.syncStatus
    // },
    windowWidth() {
      var windowwidth = this.$store.state.windowWidth
      return windowwidth
    },
    opinionStatus() {
      return this.$store.getters.opinionStatus
    },
    opinionType(){
      return this.$store.getters.opinionType
    },
    opinionState() {
      return this.$store.state.opinionState
    },
    opinionId() {
      return this.$store.state.opinionId
    },
    opinionAllData() {
      return this.$store.getters.opinionAllData
    },
    myProfile: {
      get() {
        return this.$store.getters.profile
      },
      set() {
      }
    },
  },
  mounted() {
    this.selectid = this.$route.params.eyeid;
    this.caseid = this.$route.params.caseid;
    if(this.caseid){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.tokenId;
      axios.get(process.env.VUE_APP_API_URL_HIS+'/api/opiniondata/'+this.caseid).then((res) => {
        if(res.data.status=='OK'){
          this.opinion = res.data.msg.opinion
        }
      }).catch((error) => { 
        console.log(error)
      })
    }
    this.getImage()
    this.getList()
    this.getOpinion()
  },
  methods: {
    // 放大眼底鏡圖片
    // syncMe(index) {
    //   var _self = this
    //   if(_self.syncStatus==true){
    //     _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'funduscopy_image_'+index})
    //     setTimeout(() => {
    //       var tt = document.querySelector('.viewer-close').addEventListener('click', function() {
    //         _self.closefunduscopy()
    //       })
    //     }, 400);
    //   }
    // },
    // // 關閉放大眼底鏡圖片
    // closefunduscopy() {
    //   if(this.syncStatus==true){
    //     this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closefunduscopy'})
    //   }
    // },
    // 儲存建議
    saveOpinion() {
      let _self = this
      let data = {
        type: this.$store.getters.opinionType,
        id: _self.opinionId,
        opinion: _self.opinion,
        status: 0
      }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + _self.tokenId;
      axios.put(process.env.VUE_APP_API_URL_HIS+'/api/opinion', data).then((res) => {
        if(res.data.status=='OK'){
          _self.notify('grey', _self.$t('UpdateSuccessful'), '已回覆訊息給會診醫師!')
          _self.$router.push('/opinion')
        }else{
          _self.notify('danger', _self.$t('UpdateFailed'), _self.$t('CheckField'))
          _self.$store.commit('SET_OPINION_STATE', false)
        }
      }).catch((error) => { 
        console.log(error)
      }).then(() => {
        _self.popupEyeOpinion = false
      })
    },
    // 讀取建議紀錄
    getOpinion() {
      let _self = this
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + _self.tokenId;
      axios.get(process.env.VUE_APP_API_URL_HIS+'/api/opiniondata/'+_self.selectid, ).catch((error) => { 
        console.log(error)
      })
    },
    // 回上一頁
    backFunction() {
      this.$store.commit('SET_SYNC_STATUS', false)
      // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'funduscopyBack'})
      this.$router.go(-1)
    },
    // 取眼底鏡圖片
    getImage() {
      let _self = this
      axios.get(process.env.VUE_APP_API_URL_HIS+'/api/viewfunduscopy/null/'+this.selectid).then(res => {
        if(res.data.status=='OK'){
          _self.imageurl = res.data.items
        }
      })
    },
    // 取圖表資料
    getList: function() {
      var _self = this
      _self.loading = true
      axios.get(process.env.VUE_APP_API_URL+'/admin/funduscopybyid/'+ _self.selectid).then(function(res){
        if(res.data.status=='OK'){
          _self.chartData.rows.type='speed'
          _self.chartData.rows[0].value= res.data.result.ai_dr/100
          _self.aiData.rows[0].value = res.data.result.ai_no
          _self.aiData.rows[1].value= res.data.result.ai_mild
          _self.aiData.rows[2].value= res.data.result.ai_moderate
          _self.aiData.rows[3].value= res.data.result.ai_severe
          _self.aiData.rows[4].value= res.data.result.ai_proliferative
        }
      }).catch((error) => { 
        console.log(error)
        _self.loading = false
      }).then(() => {
        _self.loading = false
      })
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
  },
  destroyed() {
    this.$store.commit('SET_OPINION_STATE', false)
    this.$store.commit('SET_OPINION_ID', null)
    this.$store.commit('SET_OPINION_TYPE',null)
    this.$store.commit('SET_OPINION_STATUS',null)
    this.$store.commit('SET_OPINION_ALL_DATA',null)
  },
}
</script>